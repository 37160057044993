import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import dayjs from 'dayjs';
import {store} from '../state/store';
import { setUser, clearUser } from '../state';

//Messages 

const ERROR_MESSAGE_FOR_REFRESH_TOKEN_FUNCTION = 'Failed to refresh access token';
const ERROR_MESSAGE_FOR_TOKEN_EXPIRATION='Redirecting to login due to token expiration';

// Base API configuration

const baseURL = process.env.REACT_APP_SERVER_URL;
const AEP_FOR_REFRESH_TOKEN= process.env.REACT_APP_REFRESH_TOKEN_API;

export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});



// Function to refresh the access token
const refreshToken = async () => {
  try {
    const response = await api.post(AEP_FOR_REFRESH_TOKEN, {}, { withCredentials: true });
    return response.data.accessToken;
  } catch (error) {
    console.error(ERROR_MESSAGE_FOR_REFRESH_TOKEN_FUNCTION, error);
    throw error;
  }
};

// Axios request interceptor to handle token refreshing
api.interceptors.request.use(async (config) => {
  const state = store.getState();
  const accessToken = state.user.accessToken;
 
  if (accessToken) {
    const decodedToken = jwtDecode(accessToken);
    const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;

    if (!isExpired) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      // Attempt to refresh the token if it has expired
      try {
        const newAccessToken = await refreshToken();
        store.dispatch(setUser({accessToken: newAccessToken})); 
        config.headers.Authorization = `Bearer ${newAccessToken}`;
      } catch (error) {
        console.error(ERROR_MESSAGE_FOR_TOKEN_EXPIRATION);
        store.dispatch(clearUser()); 
      }
    }
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;

// Email validation function remains the same
export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateMobileNumber = (mobile) => {
  const regex = /^[6-9]\d{9}$/;
  return regex.test(mobile);
}

export const validateOTP = (otp) => {
  // OTP should be exactly 6 digits
  const regex = /^\d{6}$/;
  return regex.test(otp);
};
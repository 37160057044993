import React from 'react';
import { Typography } from '@mui/material';
import AuthForm from '../../components/authForm/AuthForm';
import styles from './LoginPage.module.css';

const TYPOGRAPHY_VARIANT = "h4";
const TYPOGRAPHY_COMPONENT = "h2";
const LOGIN_PAGE_TITLE = "Login";
const AUTH_FORM_TYPE = "login";

const LoginPage = () => {
  return (
    <div className={styles.loginPage}>
      <div className={styles.loginContainer}>
        <Typography variant={TYPOGRAPHY_VARIANT} component={TYPOGRAPHY_COMPONENT}>
          {LOGIN_PAGE_TITLE}
        </Typography>
         <AuthForm formType={AUTH_FORM_TYPE} /> 
      </div>
    </div>
  );
};

export default LoginPage;

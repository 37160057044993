import React from 'react';
import { Typography } from '@mui/material';
import styles from './AddDetailsPage.module.css';
import UserDetailsForm from '../../components/updateUserDetails/UserDetailsForm';

const TYPOGRAPHY_VARIANT = "h4";
const TYPOGRAPHY_COMPONENT = "h2";
const ADD_DETAILS_PAGE_TITLE = "Add Details";

const AddDetailsPage = () => {
  

    return (
      <div className={styles.addDetailsPage}>
        <div className={styles.addDetailsContainer}>
          <Typography variant={TYPOGRAPHY_VARIANT} component={TYPOGRAPHY_COMPONENT}>
            {ADD_DETAILS_PAGE_TITLE}
          </Typography>
          <UserDetailsForm />
        </div>
      </div>
  )
}

export default AddDetailsPage;
import { useCallback } from 'react';
import { api } from '../utils';
import { useDispatch } from 'react-redux';
import { setUser } from '../state';

// API Endpoints
const AEP_TO_SEND_OTP = process.env.REACT_APP_SEND_EMAIL_OTP_API;
const AEP_TO_VERIFY_OTP = process.env.REACT_APP_VERIFY_EMAIL_OTP_API;

// Messages
const MESSAGES = {
  OTP_SENT: 'OTP sent to your email.',
  OTP_SEND_ERROR: 'Error sending OTP. Please try again.',
  OTP_VERIFY_SUCCESS: 'Operation successful!',
  OTP_VERIFY_ERROR: 'Error verifying OTP. Please try again.',
  OTP_INVALID: 'Invalid OTP. Please try again.',
};

//Navigation Routes
const ROUTE_FOR_HOME_PAGE=process.env.REACT_APP_ROUTE_FOR_HOME_PAGE;
const ROUTE_FOR_ADD_DETAILS_PAGE=process.env.REACT_APP_ROUTE_FOR_ADD_DETAILS_PAGE;
const useEmailOTP = ({ email, otp, setIsSending, setIsVerifying, setMessage, setIsOtpSent, navigate }) => {
  const dispatch = useDispatch();

  const sendEmailOTP = useCallback(async () => {
    setIsSending(true);
    try {
      const response = await api.post(AEP_TO_SEND_OTP, { email });
      if (response.status === 200 || response.status === 201) {
        setMessage(MESSAGES.OTP_SENT);
        setIsOtpSent(true);
      }
    } catch (error) {
      setMessage(MESSAGES.OTP_SEND_ERROR);
    } finally {
      setIsSending(false);
    }
  }, [email, setIsSending, setMessage, setIsOtpSent]);

  const verifyEmailOTP = useCallback(async () => {
    setIsVerifying(true);
    try {
      const response = await api.post(AEP_TO_VERIFY_OTP, { email, otp }, { withCredentials: true });
      if (response.status === 200) {
        const { data } = response;
        dispatch(setUser({
          email: email,
          role: data.data.role,
          is_verified: data.data.is_verified,
          is_profile_completed: data.data.is_profile_completed,
          access_token: data.data.access_token,
        }));

        if (data.data.is_profile_completed) {
          navigate(ROUTE_FOR_HOME_PAGE);
        } else {
          navigate(ROUTE_FOR_ADD_DETAILS_PAGE);
        }
        setMessage(MESSAGES.OTP_VERIFY_SUCCESS);
      } 
    } catch (error) {
      setMessage(error.response.data.message || MESSAGES.OTP_VERIFY_ERROR);
    } finally {
      setIsVerifying(false);
    }
  }, [email, otp, setIsVerifying, setMessage, navigate, dispatch]);

  return { sendEmailOTP, verifyEmailOTP };
};

export default useEmailOTP;

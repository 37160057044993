import { Button } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux';
import { clearUser } from '../../state';

const Home = () => {
  const dispatch= useDispatch();
  return (
    <>
    <div>Home</div>
    <Button variant="contained" onClick={() => dispatch(clearUser())}>logout</Button>
    </>
  )
}

export default Home
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  name:'',
  mobileNumber: '',
  role: '',
  isVerified: false,
  isProfileCompleted: false,
  accessToken: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      const { email,name,mobile_number, role, is_verified, is_profile_completed, access_token } = action.payload;
      state.email = email;
      state.name=name;
      state.mobileNumber = mobile_number;
      state.role = role;
      state.isVerified = is_verified;
      state.isProfileCompleted = is_profile_completed;
      state.accessToken = access_token;
    },
    clearUser(state) {
      return initialState;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;

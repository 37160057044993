import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Home, LoginPage, RegisterPage, AddDetailsPage } from './pages';
import ProtectedRoute from './components/routes/ProtectedRoute';


const ROUTE_FOR_HOME_PAGE=process.env.REACT_APP_ROUTE_FOR_HOME_PAGE;
const ROUTE_FOR_LOGIN_PAGE=process.env.REACT_APP_ROUTE_FOR_LOGIN_PAGE;
const ROUTE_FOR_REGISTER_PAGE=process.env.REACT_APP_ROUTE_FOR_REGISTER_PAGE;
const ROUTE_FOR_ADD_DETAILS_PAGE=process.env.REACT_APP_ROUTE_FOR_ADD_DETAILS_PAGE;

function App() {
  
  const isAuth = Boolean(useSelector((state) => state.user.accessToken));
  const isProfileCompleted = Boolean(useSelector((state) => state.user.isProfileCompleted));
 
 
  return (
    <Routes>
      <Route
        path={ROUTE_FOR_HOME_PAGE}
        element={
          <ProtectedRoute isAuth={isAuth} isProfileCompleted={isProfileCompleted}>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTE_FOR_LOGIN_PAGE}
        element={isAuth ? <Navigate to={ROUTE_FOR_HOME_PAGE} replace /> : <LoginPage />}
      />
      <Route
        path={ROUTE_FOR_REGISTER_PAGE}
        element={<RegisterPage />}
      />
      <Route
        path={ROUTE_FOR_ADD_DETAILS_PAGE}
        element={
          <ProtectedRoute isAuth={isAuth} isProfileCompleted={true}>
            <AddDetailsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={<Navigate to={ROUTE_FOR_LOGIN_PAGE} replace />}
      />
    </Routes>
  );
}

export default App;

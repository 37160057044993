import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../utils';
import { setUser } from '../state';
import useMessage from './useMessage';

// API Endpoints
const AEP_FOR_SIGNIN_WITH_GOOGLE = process.env.REACT_APP_GOOGLE_SIGN_IN_API;

// Messages
const ERROR_MESSAGE='Google login failed. Please try again.'

//Navigation Routes
const ROUTE_FOR_HOME_PAGE=process.env.REACT_APP_ROUTE_FOR_HOME_PAGE;
const ROUTE_FOR_ADD_DETAILS_PAGE=process.env.REACT_APP_ROUTE_FOR_ADD_DETAILS_PAGE;

const useGoogleAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setMessage } = useMessage();

  const handleGoogleLoginSuccess = async (tokenResponse) => {
    try {
      const accessToken = tokenResponse.access_token;
      const response = await api.post(AEP_FOR_SIGNIN_WITH_GOOGLE, {
        googleAccessToken: accessToken,
      }, { withCredentials: true });

      if (response.status === 200 || response.status === 201) {
        dispatch(setUser({
          email: response.data.data.email,
          name: response.data.data.name,
          is_verified: response.data.data.is_verified,
          is_profile_completed: response.data.data.is_profile_completed,
          role: response.data.data.current_role,
          access_token: response.data.data.access_token,
        }));

        if (response.data.data.is_profile_completed) {
          navigate(ROUTE_FOR_HOME_PAGE);
        } else {
          navigate(ROUTE_FOR_ADD_DETAILS_PAGE);
        }
      }
    } catch (err) {
      setMessage(ERROR_MESSAGE);
      console.error(err);
    }
  };

  const handleGoogleLogin = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });

  return {
    handleGoogleLogin,
  };
};

export default useGoogleAuth;

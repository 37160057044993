import React from 'react';
import { Typography } from '@mui/material';
import AuthForm from '../../components/authForm/AuthForm';
import styles from './RegisterPage.module.css';

const TYPOGRAPHY_VARIANT = "h4";
const TYPOGRAPHY_COMPONENT = "h2";
const REGISTER_PAGE_TITLE = "Register";
const AUTH_FORM_TYPE = "register";

const RegisterPage = () => {

  return (

      <div className={styles.registerPage}>
        <div className={styles.registerContainer}>
          <Typography variant={TYPOGRAPHY_VARIANT} component={TYPOGRAPHY_COMPONENT}>
            {REGISTER_PAGE_TITLE}
          </Typography>
          <AuthForm formType={AUTH_FORM_TYPE} />
        </div>
      </div>
  );
};

export default RegisterPage;

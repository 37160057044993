import React, { useState, useCallback, useMemo } from 'react';
import { TextField, Button, Typography, CircularProgress, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Autocomplete, InputAdornment } from '@mui/material';
import { useSelector } from 'react-redux';
import styles from './UserDetailsForm.module.css';
import { useCompleteProfile } from '../../hooks';
import { validateEmail, validateMobileNumber } from '../../utils';

// Constants for messages and options
const SITE_OPTIONS = [
  { label: 'Site 1', value: 'option1' },
  { label: 'Site 2', value: 'option2' },
  { label: 'Site 3', value: 'option3' },
];

const ROLE_LABEL = 'Role:';
const CONSUMER_LABEL = 'Consumer';
const SITE_ADMIN_LABEL = 'Site Admin';
const SELECT_SITE_LABEL = 'Select site';
const SUBMIT_BUTTON_TEXT = 'Submit';
const LENGTH_OF_MOBILE_NUMBER = 10;
const MOBILE_OR_OTP_INPUT_PATTERN = '[0-9]*';
const INPUT_ADORMENT_POSITION = 'start';
// Helper Text
const HELPER_TEXT = {
  SHORT_OTP_LENGTH: 'OTP should be of 6 digits',
  SHORT_MOBILE_NUMBER: 'Mobile number should be of 10 digits',
  INVALID_MOBILE_NUMBER: 'Invalid mobile number',
  INVALID_EMAIL: 'Invalid email address',
  SHORT_NAME: 'Name should be at least 5 characters',
  SHORT_ADDRESS: 'Address should be at least 10 characters',
}
const ROLES = {
  CONSUMER: 'consumer',
  SITE_ADMIN: 'site_admin',
};

// Constants for input field properties
const TEXTFIELD_PROPS = {
  variant: 'outlined',
  margin: 'dense',
  fullWidth: true,
};

const NAME_INPUT_PROPS = {
  name: 'name',
  label: 'Name',
  type: 'text',
  required: true,
  ...TEXTFIELD_PROPS,
};

const EMAIL_INPUT_PROPS = {
  name: 'email',
  label: 'Email',
  type: 'email',
  required: true,
  ...TEXTFIELD_PROPS,
};

const MOBILE_INPUT_PROPS = {
  name: 'mobile_number',
  label: 'Mobile Number',
  type: 'numeric',
  required: true,
  ...TEXTFIELD_PROPS,
};

const ADDRESS_INPUT_PROPS = {
  name: 'address',
  label: 'Address',
  type: 'text',
  required: true,
  ...TEXTFIELD_PROPS,
};

const RADIO_BUTTON_PROPS = {
  size: 'small',
};

const ROLE_SELECTION_RADIO_GROUP_PROPS = {
  size: 'small',
  row: true,
};

const ROLE_SELECTION_FORM_CONTROL_PROPS = {
  component: 'fieldset',
  margin: 'normal',
};

const SELECT_SITE_PROPS = {
  ...TEXTFIELD_PROPS,
  name: 'site_id',
};

const SUBMIT_BUTTON_PROPS = {
  type: 'submit',
  variant: 'contained',
  color: 'primary',
};

const MESSAGE_TYPOGRAPHY_PROPS = {
  variant: 'body2',
};

const EMPTY = '';

const CIRCULAR_PROGRESS_SIZE = 24;

// Main Form Component
const UserDetailsForm = () => {
  const userEmail = useSelector((state) => state.user.email);
  const userName = useSelector((state) => state.user.name);
  const userMobile = useSelector((state) => state.user.mobileNumber);
  const [name, setName] = useState(userName || EMPTY);
  const [email, setEmail] = useState(userEmail || EMPTY);
  const [mobile, setMobile] = useState(userMobile || EMPTY);
  const [address, setAddress] = useState(EMPTY);
  const [role, setRole] = useState(ROLES.CONSUMER);
  const [dropdownValue, setDropdownValue] = useState(null);
  const isEmailValid = useMemo(() => validateEmail(email), [email]);
  const isMobileValid = useMemo(() => validateMobileNumber(mobile), [mobile]);
  const isNameValid = useMemo(() => name?.length >= 5, [name]);
  const isAddressValid = useMemo(() => address?.length >= 10, [address]);

  const isFormValid = useMemo(() => {
    return (
      isEmailValid &&
      isMobileValid &&
      isNameValid &&
      isAddressValid &&
      (!dropdownValue || role !== ROLES.SITE_ADMIN));
  }, [isEmailValid, isMobileValid, isNameValid, isAddressValid, role, dropdownValue]);


  const { submitProfileDetails, isSubmitting, message } = useCompleteProfile();

  const handleRoleChange = useCallback((e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);
    if (selectedRole !== ROLES.SITE_ADMIN) {
      setDropdownValue(null);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await submitProfileDetails({
      name,
      mobile_number: mobile,
      email,
      address,
      role,
      site_id: dropdownValue ? dropdownValue.value : null,
    });
  };

  return (
    <form onSubmit={handleSubmit} className={styles.userDetailsForm}>
      <NameInput userName={userName} name={name} setName={setName} isSubmitting={isSubmitting} />
      <EmailInput userEmail={userEmail} email={email} setEmail={setEmail} isSubmitting={isSubmitting} isEmailValid={isEmailValid} />
      <MobileNumberInput userMobile={userMobile} mobile={mobile} setMobile={setMobile} isMobileValid={isMobileValid} isSubmitting={isSubmitting} />
      <AddressInput address={address} setAddress={setAddress} isSubmitting={isSubmitting} />
      <RoleSelection role={role} handleRoleChange={handleRoleChange} />
      {role === ROLES.SITE_ADMIN && (
        <SiteSelection value={dropdownValue} role={role} onChange={(event, newValue) => setDropdownValue(newValue)} isSubmitting={isSubmitting} />
      )}
      <Button {...SUBMIT_BUTTON_PROPS} disabled={isSubmitting} className={isFormValid ? EMPTY : styles.inactiveButton} type='submit'>
        {isSubmitting ? <CircularProgress size={CIRCULAR_PROGRESS_SIZE} className={styles.loadingSpinner} /> : SUBMIT_BUTTON_TEXT}
      </Button>
      {message && (
        <Typography className={styles.errorMessage} {...MESSAGE_TYPOGRAPHY_PROPS}>
          {message}
        </Typography>
      )}
    </form>
  );
};

// Input Components
const NameInput = ({ userName, name, setName, isSubmitting }) => (
  <TextField
    {...NAME_INPUT_PROPS}
    value={name}
    onChange={(e) => setName(e.target.value)}
    helperText={(name?.length > 0 && name?.length < 5) ? HELPER_TEXT.SHORT_NAME : EMPTY}
    slotProps={{
      input: {
        readOnly: !!userName || isSubmitting,
      },
    }}
  />
);

const EmailInput = ({ userEmail, email, setEmail, isSubmitting, isEmailValid }) => (
  <TextField
    {...EMAIL_INPUT_PROPS}
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    helperText={!isEmailValid && email?.length > 0 ? HELPER_TEXT.INVALID_EMAIL : EMPTY}
    slotProps={{
      input: {
        readOnly: !!userEmail || isSubmitting,
      },
    }}
  />
);

const MobileNumberInput = ({ userMobile, mobile, setMobile, isMobileValid, isSubmitting }) => {
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, EMPTY);
    if (value?.length <= LENGTH_OF_MOBILE_NUMBER) {
      setMobile(value);
    }
  };

  const isLengthValid = mobile?.length === LENGTH_OF_MOBILE_NUMBER;
  const helperText = !isLengthValid
    ? HELPER_TEXT.SHORT_MOBILE_NUMBER
    : !isMobileValid && mobile?.length > 0
      ? HELPER_TEXT.INVALID_MOBILE_NUMBER
      : EMPTY;

  return (
    <TextField
      {...MOBILE_INPUT_PROPS}
      value={mobile}
      onChange={handleChange}
      error={mobile?.length === LENGTH_OF_MOBILE_NUMBER && !isMobileValid}
      helperText={mobile?.length > 0 ? helperText : EMPTY}
      slotProps={{
        input: {
          startAdornment: <InputAdornment position={INPUT_ADORMENT_POSITION}>+91</InputAdornment>,
          readOnly: !!userMobile || isSubmitting,
          maxLength: LENGTH_OF_MOBILE_NUMBER,
          pattern: MOBILE_OR_OTP_INPUT_PATTERN,
        },
      }}
    />
  );
};

const AddressInput = ({ address, setAddress, isSubmitting }) => (
  <TextField
    {...ADDRESS_INPUT_PROPS}
    value={address}
    onChange={(e) => setAddress(e.target.value)}
    helperText={(address?.length > 0 && address?.length < 10) ? HELPER_TEXT.SHORT_ADDRESS : EMPTY}
    slotProps={{
      input: {
        readOnly: isSubmitting,
      },
    }}
  />
);

const RoleSelection = ({ role, handleRoleChange }) => (
  <FormControl {...ROLE_SELECTION_FORM_CONTROL_PROPS}>
    <FormLabel>{ROLE_LABEL}</FormLabel>
    <RadioGroup value={role} onChange={handleRoleChange} {...ROLE_SELECTION_RADIO_GROUP_PROPS}>
      <FormControlLabel value={ROLES.CONSUMER} control={<Radio {...RADIO_BUTTON_PROPS} />} label={CONSUMER_LABEL} />
      <FormControlLabel value={ROLES.SITE_ADMIN} control={<Radio {...RADIO_BUTTON_PROPS} />} label={SITE_ADMIN_LABEL} />
    </RadioGroup>
  </FormControl>
);

const SiteSelection = ({ value, onChange, role }) => (
  <Autocomplete
    options={SITE_OPTIONS}
    fullWidth
    getOptionLabel={(option) => option.label}
    value={value}
    onChange={onChange}
    renderInput={(params) => (
      <TextField
        {...params}
        label={SELECT_SITE_LABEL}
        required={role === ROLES.SITE_ADMIN ? true : false}
        {...SELECT_SITE_PROPS}
      />
    )}
  />
);

export default UserDetailsForm;
